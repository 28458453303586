import { Controller } from 'stimulus'
import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'

export default class extends Controller {
  static targets = ['container', 'imageContainer', 'contentContainer', 'prevButton', 'nextButton']

  connect() {
    const imageSlider = new Swiper(this.imageContainerTarget, {
      loop: true,
      navigation: {
        prevEl: this.prevButtonTarget,
        nextEl: this.nextButtonTarget,
      },
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      breakpoints: {
        1240: {
          allowTouchMove: false,
        },
      },
    })

    const contentSlider = new Swiper(this.contentContainerTarget, {
      loop: true,
      autoplay: {
        delay: 7000,
      },
      speed: 600,
    })

    imageSlider.controller.control = contentSlider
    contentSlider.controller.control = imageSlider
  }
}
