import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['overlay', 'openButton', 'closeButton', 'searchButton', 'searchOverlay']

  connect() {}

  toggleNav() {
    this.overlayTarget.classList.toggle('hidden')
    this.openButtonTarget.classList.toggle('hidden')
    this.closeButtonTarget.classList.toggle('hidden')
  }

  toggleSearch() {
    this.searchOverlayTarget.classList.toggle('hidden')
    console.log('Toggle Search')
  }
}
