import { Controller } from 'stimulus'
import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'

export default class extends Controller {
  static targets = ['container']

  connect() {
    let swiper = new Swiper(this.containerTarget, {
      loop: true,
      effect: 'fade',
      autoplay: {
        delay: 7000,
      },
      speed: 800,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      fadeEffect: {
        crossFade: true,
      },
    })
    console.log('Connected')
  }
}
